import {
  CheckoutProvider,
  useCheckout,
  useFiatPricingContext,
  usePassportProvider,
} from "@/context";
import { useCoinBalancesZkEVMQuery } from "@/hooks";
import { MenuItem } from "@biom3/react";
import { useMemo } from "react";

function Inner() {
  const { walletAddress } = usePassportProvider();
  const { checkoutClient, checkoutNetwork } = useCheckout();
  const { data: coinBalancesData } = useCoinBalancesZkEVMQuery(
    walletAddress,
    checkoutClient,
    checkoutNetwork,
  );
  const { getTotalFiatBalance } = useFiatPricingContext();

  const totalUSDBalance = useMemo(
    () => getTotalFiatBalance(coinBalancesData ?? []),
    [coinBalancesData, getTotalFiatBalance],
  );

  if (!totalUSDBalance || totalUSDBalance.amountBN.isZero()) {
    return null;
  }

  return (
    <MenuItem.Badge
      variant="emphasis"
      badgeContent={`$${totalUSDBalance.amount}`}
      testId="menu-item__badge-balance"
    />
  );
}

export default function BalanceBadge() {
  return (
    <CheckoutProvider>
      <Inner />
    </CheckoutProvider>
  );
}
