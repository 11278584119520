import * as checkout from "@imtbl/checkout-sdk";
import * as config from "@imtbl/config";

import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { appConfig } from "@/constants";
import { EnvironmentNames } from "@/types";

import { usePassportProvider } from "./PassportProvider";

type CheckoutContext = {
  checkoutClient: checkout.Checkout;
  checkoutNetwork: checkout.NetworkInfo | undefined;
  checkoutProvider: checkout.WrappedBrowserProvider | undefined;
};

const checkoutContext = createContext<CheckoutContext | null>(null);

// TODO @pano @ji: move to config.ts and reuse after configuring unit testing
export const getBaseConfig = (
  environment: EnvironmentNames,
): { baseConfig: config.ImmutableConfiguration } => {
  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.PRODUCTION,
        }),
      };
    }
    case EnvironmentNames.SANDBOX: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.SANDBOX,
        }),
      };
    }
    case EnvironmentNames.DEV: {
      const baseConfig = new config.ImmutableConfiguration({
        environment: config.Environment.SANDBOX,
      });

      return {
        baseConfig,
      };
    }

    default: {
      throw new Error("Invalid environment");
    }
  }
};

const getCheckoutConfig = (environment: EnvironmentNames) => {
  const { baseConfig } = getBaseConfig(environment);
  const checkoutConfig = {
    // This configuration is for Smart Checkout functionality. It is not used to configure individual widgets.
    bridge: { enable: false },
    onRamp: { enable: false },
    swap: { enable: false },
  };

  return {
    baseConfig,
    ...checkoutConfig,
  };
};

export function CheckoutProvider({ children }: PropsWithChildren) {
  const { passportClient, zkEvmProvider } = usePassportProvider();
  const [checkoutProvider, setCheckoutProvider] =
    useState<checkout.WrappedBrowserProvider>();

  const checkoutSDK = useMemo(() => {
    const checkoutConfig = {
      passport: passportClient,
      ...getCheckoutConfig(appConfig.ENVIRONMENT),
    };
    return new checkout.Checkout(checkoutConfig);
  }, [passportClient]);

  const [checkoutNetwork, setCheckoutNetwork] = useState<
    checkout.NetworkInfo | undefined
  >(undefined);

  useEffect(() => {
    const initialise = async () => {
      if (zkEvmProvider) {
        const provider = new checkout.WrappedBrowserProvider(zkEvmProvider);
        setCheckoutProvider(provider);

        const networkInfo = await checkoutSDK.getNetworkInfo({
          provider,
        });
        setCheckoutNetwork(networkInfo);
      }
    };

    initialise();
  }, [checkoutSDK, zkEvmProvider]);

  return (
    <checkoutContext.Provider
      value={{
        checkoutClient: checkoutSDK,
        checkoutNetwork,
        checkoutProvider,
      }}
    >
      {children}
    </checkoutContext.Provider>
  );
}

export function useCheckout() {
  const ctx = useContext(checkoutContext);
  if (!ctx) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }

  return { ...ctx };
}
